<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <div class="source">
        {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
        <router-link :to="{ path: '/' }" > {{ $t('index.index') }} </router-link>
        &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;{{ $t('index.subsidy.personFundsDetail') }}
      </div>

      <div class="news">
        <div class="personInfo">
          <div class="news-bg">{{ $t('index.personInfo.info') }}</div>
          <div class="info-title" v-if="resultList.length > 0">
            <h3><span>{{ $t('index.personInfo.name') }}：</span>{{ resultList[0].name }}</h3>
            <h3><span>{{ $t('index.personInfo.idCard') }}：</span>{{ resultList[0].idcard }}</h3>
            <h3><span>{{ $t('index.personInfo.IPass') }}：</span>{{ resultList[0].yicard }}</h3>
          </div>
          <div v-else style="padding: 2rem 0; text-align: center">
            <h3>{{ $t('index.subsidy.noData') }}</h3>
          </div>
        </div>
      </div>
      <div class="news" v-if="resultList.length > 0">
        <div class="news-bg">{{ $t('index.subsidy.personDetail') }}</div>
        <!-- 音频播放 -->
        <audio :src="audioSrc" autoplay style="display: none"></audio>
        <!-- 查看政策 -->
        <van-popup
          v-model="showPopup"
          :round="true"
          :lock-scroll="true"
          style="width: 80%; max-height: 80%; text-align: left; z-index: 999999"
          :overlay-style="{ zIndex: 2001 }"
        >
          <van-cell :title="$t('index.subsidy.grantObj')" :label="popupData.target" />
          <van-cell :title="$t('index.subsidy.auditProcess')" :label="popupData.process" />
          <van-cell :title="$t('index.subsidy.grantStandard')" :label="popupData.standard" />
          <van-cell :title="$t('index.subsidy.grantTime')" :label="popupData.times" />
          <van-cell :title="$t('index.subsidy.consultUnit')" :label="popupData.company" />
          <van-cell :title="$t('index.subsidy.consultPhone')" :label="popupData.phone" />
          <van-cell
            :title="$t('index.subsidy.policyStatute')"
            :label="popupData.zurl"
            v-if="popupData.zurl"
          />
        </van-popup>
        <ul class="detail">
          <li v-for="(item, index) in resultList" :key="item.id">
            <!-- <h3>{{ item.capitalname }}</h3> -->
            <div style="display: flex">
              <div class="title-box" style="flex: 1">
                <span class="title">{{ item.capitalname }}</span>
              </div>
              <div class="icon-box">
                <img
                  style="width: 20px; margin: 0 5px"
                  class="icon"
                  src="../assets/icon/play.png"
                  v-show="!item.playStatus"
                  @click="playControl(index)"
                />
                <img
                  style="width: 20px; margin: 0 5px"
                  class="icon"
                  src="../assets/icon/play_selected.png"
                  v-show="item.playStatus"
                  @click="playControl(index)"
                />
                <img
                  style="width: 20px; margin: 0 5px"
                  class="icon"
                  src="../assets/icon/policy.png"
                  @click="seePolicy(index)"
                />
              </div>
            </div>
            <p>{{ $t('index.subsidy.grantTime') }}：{{ item.time }}</p>
            <p>{{ $t('index.subsidy.grantAddress') }}：{{ item.address }}</p>
            <p>{{ $t('index.subsidy.grantMoney') }}：{{ item.money ? parseFloat(item.money).toFixed(2) : "-" }}元 </p>
            <div class="detail-footer">
              <div>{{ $t('index.subsidy.grantStatus') }}：{{ item.state }}</div>
              <div class="r" @click="show(item.id)">
                <a href="javascript:void(0);">{{ $t('public.fastComplaint') }}</a>
                <!-- <router-link :to="{ name: 'tousu', params: { fundId: item.id }}">快速投诉</router-link> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
      <tousu_modal :moneyId="moneyId"></tousu_modal>

      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "../utils/CryptoJS";
import BASE from "../utils/base";
import tousu_modal from "../components/tousu_modal";

import { Toast } from "vant";

export default {
  name: "app",
  components: { tousu_modal },
  mounted() {
    // this.show()
    console.log("t>>>", this.$route);
  },
  activated() {
    // when the Vue app is booted up, this is run automatically.

    let idcard = this.$route.params.idcard || "";
    // let capitalname = this.$route.params.name||undefined;
    let capitalname = this.$route.params.name || "";
    let idrealcard = CryptoJS.decryptsfz(idcard);
    let reg = /^[\u2E80-\u9FFF]|·+$/; //Unicode编码中的汉字范围

    // 数据查询参数
    let queryParams = {
      url: "zxSuperviseMoney/list",
      params: { idcard: idcard, capitalname },
    };

    // 判断是否为汉字
    if (reg.test(idrealcard)) {
      queryParams = {
        url: "zxSuperviseMoney/listbyname",
        params: { name: idcard, capitalname },
      };
    }

    let that = this;
    this.axios
      .get(BASE.genUrl(queryParams.url, queryParams.params))
      .then((res) => {
        let data = res.data;
        if (data.result) {
          that.resultList = data.result.records;
        }
      });

    // 获取浏览量
    // this.axios
    //   .get(BASE.genUrl('zxCommViews/list', {type:'%E6%99%BA%E6%85%A7%E7%9B%91%E7%9D%A3'}))
    //   .then(res => {
    //     console.log('浏览量', res)
    //     let data = res.data;
    //     if(data.result) {
    //       that.todayView = data.result.today;
    //       that.totalView = data.result.total;
    //     }
    // });
  },
  computed: {
    prev: function () {
      return this.pageNo - 1 <= 0 ? 1 : this.pageNo - 1;
    },
    next: function () {
      return this.result.length >= this.pageSize
        ? this.pageNo + 1
        : this.pageNo;
    },
  },
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    resultList: [],
    phone: "",
    yzm: "",
    issend: false,
    tousuType: "金额不对",
    moneyId: "",
    count_over: false,
    timer: null,
    yzm_text: "获取验证码",

    // 播放内容
    audioSrc: "",
    // 查看政策弹出框
    showPopup: false,
    // 弹出框数据
    popupData: {},
  }),
  methods: {
    show(id) {
      this.moneyId = id;
      this.$modal.show("modal");
    },
    hide() {
      this.$modal.hide("modal");
    },

    /**
     * 音频播放
     */
    playControl(index) {
      this.audioSrc = "";
      const that = this;
      const info = this.resultList[index];
      this.axios
        .post(BASE.genUrl("zxSuperviseMoney/getvoice", { id: info.id }))
        .then(function (res) {
          const arr = that.resultList.filter((e, i) => {
            if (i == index) {
              e.playStatus = !e.playStatus;
              if (e.playStatus) that.audioSrc = res.data.result;
            } else {
              e.playStatus = false;
            }
            return e;
          });
          that.resultList = arr;
        });
    },
    /**
     * 政策查看
     */
    seePolicy(index) {
      const that = this;
      const info = this.resultList[index];
      this.axios
        .get(
          BASE.genUrl("zxSupervisePolicydetail/list", {
            capitalid: info.capitalname,
          })
        )
        .then((res) => {
          if (res.data.result.records.length === 0) {
            Toast("暂无政策");
          } else {
            that.popupData = res.data.result.records[0];
            that.showPopup = !that.showPopup;
          }
        });
    },
  },
};
</script>
<style scoped>
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}

.modal h3 {
  margin-bottom: 0.5rem;
}

.title-box {
  font-size: 14px;
  font-weight: bold;
}

.button {
  display: block;
  background: #b51414;
  color: #fff;
  border: 0;
  padding: 0.5rem 2rem;
  margin: 1rem auto 0rem;
  border-radius: 0.5rem;
  width: 50%;
  font-size: 16px;
}

.modal {
  /* text-align: center; */
  font-size: 16px;
  border-radius: 0.5rem;
  /* padding: .5rem; */
}

.modal input {
  /* text-indent: .2rem; */
  padding: 0.5rem 1rem;
  font-size: 16px;
  width: 50%;
}

.yzm-input {
  /* border: 1px solid #999;
  border-radius: .5rem;
  border-right: 0; */
}

.yzm-button {
  padding: 0.5rem 1rem;
  background: #b51414;
  color: #fff;
  border: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  font-size: 16px;
  /* width: 50%; */
  margin-bottom: 0.5rem;
}

.disabled {
  background: #ccc;
  color: #b51414;
}

.gray {
  background: #ccc;
  color: #fff;
}

.modal select {
  /* width: 50%; */
  font-size: 14px;
  text-align: center;
  padding: 0.5rem 1rem;
  /* margin-top: 1rem; */
}

.detail-footer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.detail-footer div {
  width: 50%;
}

.detail-footer div.r {
  text-align: right;
}

.detail-footer div.r a {
  border: 1px solid #b51414;
  color: #b51414;
  padding: 0.1rem 0.5rem;
}

.personInfo {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.5rem;
}

.footer {
  padding: 0.5rem 0;
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.detail {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: left;
}

.personInfo span {
  color: #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.info-title {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

h3 {
  margin-bottom: 0.3rem;
}

.detail p {
  font-size: 14px;
  line-height: 25px;
}

.detail li {
  margin: 0 0.5rem;
  border-bottom: 1px solid #ccc;
}
</style>
